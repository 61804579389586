import { Request } from '@/http/request'

// vos用户设置--->列表 
export function checkList(parameter: any) {
    return Request.axiosInstance.post('/open/es-vos-check/list', parameter)
}
export function vosUserIndustry(parameter: any) {
    return Request.axiosInstance.post('/open/voice-vos-user-industry/query', parameter)
}

export default { checkList,vosUserIndustry }